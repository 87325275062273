import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { switchMap, map, Observable, of, forkJoin } from "rxjs";
import { catchError } from "rxjs/operators";
import { Constants } from "src/app/constants/constants";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { Broadcaster, MediaConnectFlow, Source } from "src/app/models/shared";
import {
    AdaptiveChannel,
    DeliveryChannel,
    ZixiPullTarget,
    ZixiPushTarget,
    PublishingTarget,
    RistTarget,
    UdpRtpTarget,
    RtmpPushTarget,
    SrtTarget,
    NdiTarget,
    MediaLiveHttpTarget,
    MediaconnectCDITarget,
    MediaconnectJPEGXSTarget,
    MediaconnectEntitlementTarget,
    MediaLiveChannel,
    FailoverChannel
} from "src/app/pages/channels/channel";
import { Zec } from "@zixi/models";
import { ZecsService } from "src/app/pages/zecs/zecs.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { Feeder, Receiver } from "../../zecs/zecs/zec";
import { RemoteAccess } from "../../remote-access/remote-access";
import { RemoteAccessService } from "../../remote-access/remote-access.service";

export enum OBJECT_TYPE {
    SOURCE = "source",
    ADAPTIVE_CHANNEL = "adaptive_channel",
    DELIVERY_CHANNEL = "delivery_channel",
    ZIXI_PULL_TARGET = "zixi_pull",
    ZIXI_PUSH_TARGET = "zixi_push",
    HTTP_PUBLISHING_TARGET = "publishing_target",
    RIST_TARGET = "rist",
    UDP_RTP_TARGET = "udp_rtp",
    RTMP_PUSH_TARGET = "rtmp_push",
    RTMP_TARGET = "rtmp",
    SRT_TARGET = "srt_targets",
    NDI_TARGET = "ndi_targets",
    ZEC = "zec",
    BROADCASTER = "broadcaster",
    FEEDER = "feeder",
    RECEIVER = "receiver",
    REMOTE_ACCESS = "remote_access",
    HTTP_TARGET = "http",
    CDI_TARGET = "cdi",
    JPEGXS_TARGET = "jpegxs",
    ENTITLEMENT_TARGET = "entitlement",
    MEDIALIVE_HTTP = "medialive_http",
    PULL_TARGET = "pull",
    PUSH_TARGET = "push",
    MEDIACONNECT_CHANNEL = "mediaconnect_channel",
    MEDIALIVE_CHANNEL = "medialive_channels",
    FAILOVER_CHANNEL = "failover_channel"
}

type ACTIVE_OBJECT_MODELS =
    | Source
    | AdaptiveChannel
    | DeliveryChannel
    | ZixiPullTarget
    | ZixiPushTarget
    | PublishingTarget
    | RistTarget
    | UdpRtpTarget
    | RtmpPushTarget
    | SrtTarget
    | NdiTarget
    | Zec
    | Broadcaster
    | Feeder
    | Receiver
    | RemoteAccess
    | MediaLiveHttpTarget
    | MediaconnectCDITarget
    | MediaconnectJPEGXSTarget
    | MediaconnectEntitlementTarget
    | MediaConnectFlow
    | MediaLiveChannel
    | FailoverChannel;

interface ActiveObjectsIdsByType {
    [OBJECT_TYPE.SOURCE]?: number[];
    [OBJECT_TYPE.ADAPTIVE_CHANNEL]?: number[];
    [OBJECT_TYPE.DELIVERY_CHANNEL]?: number[];
    [OBJECT_TYPE.ZIXI_PULL_TARGET]?: number[];
    [OBJECT_TYPE.ZIXI_PUSH_TARGET]?: number[];
    [OBJECT_TYPE.HTTP_PUBLISHING_TARGET]?: number[];
    [OBJECT_TYPE.RIST_TARGET]?: number[];
    [OBJECT_TYPE.UDP_RTP_TARGET]?: number[];
    [OBJECT_TYPE.RTMP_PUSH_TARGET]?: number[];
    [OBJECT_TYPE.RTMP_TARGET]?: number[];
    [OBJECT_TYPE.SRT_TARGET]?: number[];
    [OBJECT_TYPE.NDI_TARGET]?: number[];
    [OBJECT_TYPE.ZEC]?: number[];
    [OBJECT_TYPE.BROADCASTER]?: number[];
    [OBJECT_TYPE.FEEDER]?: number[];
    [OBJECT_TYPE.RECEIVER]?: number[];
    [OBJECT_TYPE.REMOTE_ACCESS]?: number[];
    [OBJECT_TYPE.HTTP_TARGET]?: number[];
    [OBJECT_TYPE.CDI_TARGET]?: number[];
    [OBJECT_TYPE.JPEGXS_TARGET]?: number[];
    [OBJECT_TYPE.ENTITLEMENT_TARGET]?: number[];
    [OBJECT_TYPE.MEDIALIVE_HTTP]?: number[];
    [OBJECT_TYPE.PULL_TARGET]?: number[];
    [OBJECT_TYPE.PUSH_TARGET]?: number[];
    [OBJECT_TYPE.MEDIACONNECT_CHANNEL]?: number[];
    [OBJECT_TYPE.MEDIALIVE_CHANNEL]?: number[];
    [OBJECT_TYPE.FAILOVER_CHANNEL]?: number[];
}

export interface ActiveObject {
    model: ACTIVE_OBJECT_MODELS;
    type:
        | OBJECT_TYPE.SOURCE
        | OBJECT_TYPE.ADAPTIVE_CHANNEL
        | OBJECT_TYPE.DELIVERY_CHANNEL
        | OBJECT_TYPE.ZIXI_PULL_TARGET
        | OBJECT_TYPE.ZIXI_PUSH_TARGET
        | OBJECT_TYPE.HTTP_PUBLISHING_TARGET
        | OBJECT_TYPE.RIST_TARGET
        | OBJECT_TYPE.UDP_RTP_TARGET
        | OBJECT_TYPE.RTMP_PUSH_TARGET
        | OBJECT_TYPE.RTMP_TARGET
        | OBJECT_TYPE.SRT_TARGET
        | OBJECT_TYPE.NDI_TARGET
        | OBJECT_TYPE.ZEC
        | OBJECT_TYPE.BROADCASTER
        | OBJECT_TYPE.FEEDER
        | OBJECT_TYPE.RECEIVER
        | OBJECT_TYPE.REMOTE_ACCESS
        | OBJECT_TYPE.HTTP_TARGET
        | OBJECT_TYPE.CDI_TARGET
        | OBJECT_TYPE.JPEGXS_TARGET
        | OBJECT_TYPE.ENTITLEMENT_TARGET
        | OBJECT_TYPE.MEDIALIVE_HTTP
        | OBJECT_TYPE.PULL_TARGET
        | OBJECT_TYPE.PUSH_TARGET
        | OBJECT_TYPE.MEDIACONNECT_CHANNEL
        | OBJECT_TYPE.MEDIALIVE_CHANNEL
        | OBJECT_TYPE.FAILOVER_CHANNEL;
}

@Injectable({
    providedIn: "root"
})
export class NetworkObjectsStateService {
    readonly SUPPORTED_OBJECTS_TYPES = Object.values(OBJECT_TYPE) as string[];
    readonly TYPE_TO_DATA_FUNCTION_MAP = {
        [OBJECT_TYPE.SOURCE]: this.sourcesService.getSourcesNew.bind(this.sourcesService),
        [OBJECT_TYPE.ADAPTIVE_CHANNEL]: this.channelsService.getAdaptiveChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE.DELIVERY_CHANNEL]: this.channelsService.getDeliveryChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE.ZIXI_PULL_TARGET]: this.targetsService.getPullTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.ZIXI_PUSH_TARGET]: this.targetsService.getPushTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.HTTP_PUBLISHING_TARGET]: this.targetsService.getHttpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.RIST_TARGET]: this.targetsService.getRistTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.UDP_RTP_TARGET]: this.targetsService.getUdpRtpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.RTMP_PUSH_TARGET]: this.targetsService.getRtmpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.RTMP_TARGET]: this.targetsService.getRtmpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.SRT_TARGET]: this.targetsService.getSrtTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.NDI_TARGET]: this.targetsService.getNdiTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.ZEC]: this.zecsService.getZecsNew.bind(this.zecsService),
        [OBJECT_TYPE.BROADCASTER]: this.bxsService.getBroadcastersNew.bind(this.bxsService),
        [OBJECT_TYPE.FEEDER]: this.zecsService.getFeedersNew.bind(this.zecsService),
        [OBJECT_TYPE.RECEIVER]: this.zecsService.getReceiversNew.bind(this.zecsService),
        [OBJECT_TYPE.REMOTE_ACCESS]: this.remoteAccessService.getRemoteAccessNew.bind(this.remoteAccessService),
        [OBJECT_TYPE.HTTP_TARGET]: this.targetsService.getHttpTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.CDI_TARGET]: this.targetsService.getCDITargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.JPEGXS_TARGET]: this.targetsService.getJPEGXSTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.ENTITLEMENT_TARGET]: this.targetsService.getEntitlementTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.MEDIALIVE_HTTP]: this.targetsService.getMediaLiveHTTPTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.PULL_TARGET]: this.targetsService.getPullTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.PUSH_TARGET]: this.targetsService.getPushTargetsNew.bind(this.targetsService),
        [OBJECT_TYPE.MEDIACONNECT_CHANNEL]: this.channelsService.getMediaConnectFlowChannelsNew.bind(
            this.channelsService
        ),
        [OBJECT_TYPE.MEDIALIVE_CHANNEL]: this.channelsService.getMediaLiveChannelsNew.bind(this.channelsService),
        [OBJECT_TYPE.FAILOVER_CHANNEL]: this.channelsService.getFailoverChannelsNew.bind(this.channelsService)
    };

    constructor(
        private http: HttpClient,
        private sourcesService: SourcesService,
        private channelsService: ChannelsService,
        private targetsService: TargetsService,
        private zecsService: ZecsService,
        private bxsService: BroadcastersService,
        private remoteAccessService: RemoteAccessService
    ) {}

    public getActiveObjectsStream(networkId: number): Observable<ActiveObject[]> {
        return of(networkId).pipe(
            switchMap(networkId => this.getActiveObjectsTypes(networkId)),
            switchMap(activeObjectsTypes => {
                const activeObjectsStreams: Observable<ActiveObject[]>[] = [];
                for (const type in activeObjectsTypes) {
                    const ids = activeObjectsTypes[type];
                    const getObjectsDataFunction = this.TYPE_TO_DATA_FUNCTION_MAP[type] as (
                        ids: number[]
                    ) => Observable<ACTIVE_OBJECT_MODELS[]>;
                    const activeObjects$ = getObjectsDataFunction(ids).pipe(
                        map(activeObjects => {
                            if (!activeObjects) {
                                return null;
                            }
                            return activeObjects.map(activeObject => {
                                return {
                                    model: activeObject,
                                    type
                                } as ActiveObject;
                            });
                        })
                    );
                    activeObjectsStreams.push(activeObjects$);
                }

                if (!activeObjectsStreams.length) {
                    return of([]);
                }
                return forkJoin(activeObjectsStreams).pipe(
                    map((activeObjects: ActiveObject[][]) => {
                        return activeObjects
                            .filter(activeObjects => !!activeObjects)
                            .reduce((acc, objectsTypeData) => acc.concat(objectsTypeData), []);
                    })
                );
            })
        );
    }

    private getActiveObjectsTypes(networkId): Observable<ActiveObjectsIdsByType> {
        const req$ = this.http.get<{
            success: boolean;
            result: {
                object_type: string;
                object_id: number;
            }[];
        }>(Constants.apiUrl + Constants.apiUrls.network + "/" + networkId + "/objects");

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error("Fail to fetch active objects data");
                }

                const activeObjectIdsByType = response.result.reduce((acc, currentValue) => {
                    const objectTypeKey = currentValue.object_type;

                    if (!acc[objectTypeKey]) {
                        acc[objectTypeKey] = [];
                    }
                    acc[objectTypeKey].push(currentValue.object_id);
                    return acc;
                }, {});

                const unsupportedTypes = [];
                for (const key in activeObjectIdsByType) {
                    const isSupported = this.SUPPORTED_OBJECTS_TYPES.includes(key);
                    if (!isSupported) {
                        unsupportedTypes.push(key);
                        delete activeObjectIdsByType[key];
                    }
                }

                if (unsupportedTypes.length) {
                    // TODO: in the future send to sentry
                    // eslint-disable-next-line no-console
                    console.error(
                        `Network Active Objects: couldn't render unsupported types ${unsupportedTypes.join(", ")}`
                    );
                }

                return activeObjectIdsByType;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.error(error);
                return of({});
            })
        );
    }
}
